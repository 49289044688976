/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import Layout from './../../components/layout';
import Content from '../../components/content';
import LogoGallery from '../../components/home/logo-gallery';
import SEO from "../../components/seo";
import ReactHubspotForm from '../../components/react-hubspot-form';
import { getOgImage, sanitize } from "./../../utils/functions";

import './style.scss';

/**
 * StateOfOnlineConversations component.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const StateOfOnlineConversations = ( props ) => {

	const [ submitted, setSubmitted ] = useState( false );

	const { pageContext: { onlineConversion, homePage } } = props;
	const {
		seo,
		title,
		uri,
		ourPartnersMeta: {
			ourPartners,
			headline
		},
		casestudyPostMeta: {
			caseStudy: {
				formHeadline,
				formId,
				formPortalid,
				thankYouMessage,
				downloadLabel,
				downloadLink
			},
		},
	} = onlineConversion;
	const { frontPageMeta: { sectionSix } } = homePage;
	const openGraphImage = getOgImage( seo );

	const containerClass = classnames( 'large-4 medium-12 small-12 columns online-conversation__form-wrap', {
		'form-submitted' : submitted,
	} );

	return(
		<Layout title={ title } uri={ uri } className="online-conversation">
			<SEO
				title={ title }
				seoData={ seo }
				uri={ uri }
				header={ { siteTitle: 'OpenWeb' } }
				openGraphImage={ openGraphImage }
			/>

			<h1 className="online-conversation__title" dangerouslySetInnerHTML={ { __html: sanitize( title ) } } />

			<div className="row align-justify">
				<div className="large-7 medium-12 small-12 columns">
					<Content page={ onlineConversion } />
				</div>

				<div className={ containerClass }>
					{
						! submitted ? <h2 dangerouslySetInnerHTML={ { __html: sanitize( formHeadline ) } } /> : null
					}

					{ ! isEmpty( formPortalid ) && ! isEmpty( formId ) ? (
						<>
							<ReactHubspotForm
								portalId={ formPortalid }
								formId={ formId }
								setSubmitted={ setSubmitted }
								tagName="SoOC_Submit"
								tagParent="CASE STUDIES"
							/>

							{
								submitted ? (
									<div className="thankyou-message">
										<div dangerouslySetInnerHTML={ { __html: sanitize( thankYouMessage ) } } />
										<a className="online-conversation__download-media" href={ downloadLink } rel="noopener noreferrer" target="_blank" download>{ downloadLabel }</a>
									</div>
								) : null
							}
						</>
					) : null }
				</div>
			</div>
			{
				ourPartners ? (
					<>
						<h3 className="online-conversation__headline" dangerouslySetInnerHTML={ { __html: sanitize( headline ) } } />
						<LogoGallery meta={ sectionSix } />
					</>
				) : null
			}
		</Layout>
	);

};

export default StateOfOnlineConversations;
