/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { sanitize } from '../../utils/functions';

/**
 * Internal dependencies.
 */
import './style.scss';

/**
 * Content Component.
 *
 * @param {Object} page Page props.
 *
 * @return {jsx}
 */
const Content = ({ page }) => {

	const { content } = page;

	return (
		<section className="content-section">
			<div className="row">
				<div className="small-12 columns">
					<div className="content-section__text" dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
				</div>
			</div>
		</section>
	);

};

Content.propTypes = {
	page: PropTypes.object,
};

Content.defaultProps = {
	page: {
		content: ''
	}
};

export default Content;
